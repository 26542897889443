<template>
  <v-app>
    <v-app-bar app style="z-index: 100">
      <v-toolbar-title>Dark Dragons Astronomy - Mosaic Planner</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <MosaicPlanner />
    </v-main>

    <v-divider></v-divider>

    <v-footer color="">
      <v-row no-gutters>
        <v-col class="text-center text-caption" cols="12">
          This site uses data and technology from the following sources:
        </v-col>
        <v-col class="text-center" cols="12">
          <v-btn
            small
            text
            href="https://aladin.u-strasbg.fr/AladinLite/"
            target="_blank"
            >Aladin Lite</v-btn
          >
          <v-btn
            small
            text
            href="https://heasarc.gsfc.nasa.gov/W3Browse/all/gsc.html"
            target="_blank"
            >HST Guide Star Catalog</v-btn
          >
        </v-col>

        <v-col class="text-center text-caption" cols="12">
          {{ new Date().getFullYear() }} —
          <strong>Dark Dragons Astronomy</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import MosaicPlanner from "./components/MosaicPlanner";

export default {
  name: "App",

  components: {
    MosaicPlanner,
  },

  data: () => ({
    //
  }),
};
</script>
