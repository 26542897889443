<template>
  <div>
    <v-container>
      <h2>
        Imaging Setup
        <v-btn icon v-on:click="showHelp = true">
          <v-icon dark> mdi-help-circle </v-icon>
        </v-btn>
      </h2>

      <v-dialog v-model="showHelp" width="500">
        <v-card>
          <v-card-title> Mosaic Planner Help </v-card-title>

          <v-card-text>
            <p>
              The first step is to set your imaging setup parameters. You will
              need to know your Sensor width and height (in pixels) and the size
              of those pixels. Please either choose one of the cameras from the
              list, or find these values on your manufacturer's website.
            </p>

            <p>
              Next you need to set the focal length of your imaging system. This
              should include any adjustments made by focal reducers, barlows,
              etc. For example, if your telescope has an 800mm focal length, and
              you have a 0.75x reducer, you will enter 600mm here. If you are
              not sure of your final focal length, you can upload a FITS file
              taken of the night sky to http://nova.astrometry.net/upload and it
              will solve the image and give you the information you need.
            </p>

            <p>
              The next step is to choose your target. You can search for a
              target, or enter the RA and Dec values directly. Please note that
              this tool accepts RA values in decimal degrees only.
            </p>

            <p>
              Once your target is centered, you can now adjust the rotation,
              number of frames, and overlap of each frame. When you are
              satisfied with your framing, you can click either the "Download
              Image X" or "View Image X" buttons at the bottom. The View buttons
              will show a virtual star field in your browser. The Download
              buttons will download a FITS version of the virtual star field you
              can use to "Solve and GoTo" in your imaging software of choice.
            </p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showHelp = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col>
          <v-select :items="cameraNames" v-model="selectedCamera" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Sensor Width"
            suffix="px"
            type="number"
            v-model="cameraWidthPixels"
          />
        </v-col>
        <v-col>
          <v-text-field
            label="Sensor Height"
            suffix="px"
            type="number"
            v-model="cameraHeightPixels"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Pixel Width"
            suffix="µm"
            type="number"
            v-model="pixelWidth"
            step="0.01"
          />
        </v-col>
        <v-col>
          <v-text-field
            label="Pixel Height"
            suffix="µm"
            type="number"
            v-model="pixelHeight"
            step="0.01"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Focal Length"
            suffix="mm"
            type="number"
            v-model="focalLengthMM"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Sensor Resolution</h3>
          {{ sensorWidthArcSecPerPixel.toFixed(4) }}"/px x
          {{ sensorHeightArcSecPerPixel.toFixed(4) }}"/px
        </v-col>
        <v-col>
          <h3>Sensor FOV</h3>
          {{ sensorFovWidthArcSec.toFixed(2) }}" x
          {{ sensorFovHeightArcSec.toFixed(2) }}" |
          {{ (sensorFovWidthArcSec / 60).toFixed(2) }}' x
          {{ (sensorFovHeightArcSec / 60).toFixed(2) }}' |
          {{ (sensorFovWidthArcSec / 60 / 60).toFixed(2) }}&deg; x
          {{ (sensorFovHeightArcSec / 60 / 60).toFixed(2) }}&deg;
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <h2>Target</h2>
      <v-form ref="form" @submit.prevent="searchObject">
        <v-row>
          <v-col>
            <v-text-field
              label="Search"
              type="text"
              v-model="search"
              :error-messages="searchError"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn block @click="searchObject" :loading="searching"
              >Search</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col>
          <v-text-field
            label="RA (decimal degrees):"
            id="ra"
            type="text"
            v-model="ra"
            :messages="raDecimalHoursMessage"
          />
        </v-col>
        <v-col>
          <v-text-field
            label="Dec (decimal degrees):"
            id="dec"
            type="text"
            v-model="dec"
            :messages="decDecimalDegreesMessage"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div id="aladin-lite-div" style="width: 100%; height: 800px"></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-slider max="180" min="0" v-model="rotation" label="Rotation">
            <template v-slot:append>
              <v-text-field
                v-model="rotation"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                min="0"
                max="180"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="X Frames"
            type="number"
            min="1"
            max="20"
            v-model="xFrames"
          />
        </v-col>
        <v-col>
          <v-text-field
            label="Y Frames"
            type="number"
            min="1"
            max="20"
            v-model="yFrames"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="X Overlap"
            type="number"
            min="1"
            max="50"
            suffix="%"
            v-model="xOverlap"
          />
        </v-col>
        <v-col>
          <v-text-field
            label="Y Overlap"
            type="number"
            min="1"
            max="50"
            suffix="%"
            v-model="yOverlap"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-container v-for="i in xFrames * yFrames" v-bind:key="i">
          <v-btn text @click="download(i)">Download Image {{ i }}</v-btn>
          <v-btn text @click="view(i)">View Image {{ i }}</v-btn>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* global A */
/* global Sesame */

import { debounce } from "lodash";

class SensorBox {
  constructor(
    width,
    height,
    rotation,
    xFrames,
    yFrames,
    xOverlap,
    yOverlap,
    focalLength,
    pixelSizeX,
    pixelSizeY,
    cameraWidthPixels,
    cameraHeightPixels,
    options
  ) {
    this.width = width;
    this.height = height;
    this.rotation = rotation;
    this.focalLength = focalLength;
    this.pixelSizeX = pixelSizeX;
    this.pixelSizeY = pixelSizeY;
    this.cameraWidthPixels = cameraWidthPixels;
    this.cameraHeightPixels = cameraHeightPixels;

    this.xFrames = xFrames;
    this.yFrames = yFrames;
    this.xOverlap = xOverlap;
    this.yOverlap = yOverlap;

    this.rectCenters = [];
    this.centerPoint = [];

    options = options || {};
    this.color = options["color"] || "rgba(255,100,100,0.4)";
    this.lineWidth = options["lineWidth"] || 2;

    this.overlay = null;

    this.isShowing = true;
    this.isSelected = false;

    this.ctx = null;
  }

  setOverlay(overlay) {
    this.overlay = overlay;
  }

  show() {
    if (this.isShowing) {
      return;
    }
    this.isShowing = true;
    if (this.overlay) {
      this.overlay.reportChange();
    }
  }

  hide() {
    if (!this.isShowing) {
      return;
    }
    this.isShowing = false;
    if (this.overlay) {
      this.overlay.reportChange();
    }
  }

  select() {
    if (this.isSelected) {
      return;
    }
    this.isSelected = true;
    if (this.overlay) {
      this.overlay.reportChange();
    }
  }

  deselect() {
    if (!this.isSelected) {
      return;
    }
    this.isSelected = false;
    if (this.overlay) {
      this.overlay.reportChange();
    }
  }

  setLineWidth(lineWidth) {
    if (this.lineWidth == lineWidth) {
      return;
    }
    this.lineWidth = lineWidth;
    this.overlay.reportChange();
  }

  setColor(color) {
    if (this.color == color) {
      return;
    }
    this.color = color;
    this.overlay.reportChange();
  }

  getRaDec(i) {
    i -= 1;

    const rectCenter = this.rectCenters[i];
    const adjustedCenter = [
      rectCenter[0] - this.centerPoint[0],
      rectCenter[1] - this.centerPoint[1],
    ];

    const rotation = this.rotation * (Math.PI / 180.0);
    const cos = Math.cos(rotation);
    const sin = Math.sin(rotation);

    const newX =
      cos * adjustedCenter[0] - sin * adjustedCenter[1] + this.centerPoint[0];
    const newY =
      cos * adjustedCenter[1] + sin * adjustedCenter[0] + this.centerPoint[1];

    return this.overlay.view.aladin.pix2world(newX, newY);
  }

  draw(ctx, projection, frame, width, height) {
    this.ctx = ctx;

    // const raDec = this.overlay.view.aladin.getRaDec();

    if (!this.isShowing) {
      return;
    }
    ctx.save();

    const rotationRadians = (this.rotation * Math.PI) / 180;

    const centerX = width / 2;
    const centerY = height / 2;

    this.centerPoint = [centerX, centerY];

    ctx.translate(centerX, centerY);
    ctx.rotate(rotationRadians);
    ctx.translate(-centerX, -centerY);

    ctx.moveTo(centerX - this.width / 2, centerY - this.height / 2);

    const overlapWidth = (this.width * this.xOverlap) / 100;
    const overlapHeight = (this.height * this.yOverlap) / 100;

    const fullWidth =
      this.width * this.xFrames - overlapWidth * (this.xFrames - 1);
    const fullHeight =
      this.height * this.yFrames - overlapHeight * (this.yFrames - 1);

    const topLeftX = centerX - fullWidth / 2;
    const topLeftY = centerY - fullHeight / 2;

    ctx.fillStyle = this.color;
    ctx.strokeStyle = this.color;
    ctx.lineWidth = this.lineWidth;

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = `normal bold ${Math.round(this.height / 2)}px sans-serif`;

    let counter = 1;

    this.rectCenters = [];

    for (let i = 0; i < this.xFrames; i++) {
      for (let j = 0; j < this.yFrames; j++) {
        const rectTopLeftX = topLeftX + (this.width - overlapWidth) * i;
        const rectTopLeftY = topLeftY + (this.height - overlapHeight) * j;

        this.rectCenters.push([
          rectTopLeftX + this.width / 2,
          rectTopLeftY + this.height / 2,
        ]);

        ctx.fillText(
          counter.toString(),
          rectTopLeftX + this.width / 2,
          rectTopLeftY + this.height / 2
        );

        ctx.strokeRect(rectTopLeftX, rectTopLeftY, this.width, this.height);
        counter++;
      }
    }

    ctx.restore();
  }
}

const zeroPad = (num, places) => String(num).padStart(places, "0");

export default {
  name: "HelloWorld",
  data: function () {
    return {
      showHelp: false,
      search: "M31",
      searching: false,
      searchError: "",
      ra: 10.68470833,
      dec: 41.26875,
      dssVersion: "phase2_gsc2",
      cameraWidthPixels: 6248,
      cameraHeightPixels: 4176,
      pixelWidth: 3.76,
      pixelHeight: 3.76,
      focalLengthMM: 800,
      rotation: 0,
      mousePositionX: 0,
      mousePositionY: 0,
      mousePositionRA: 0,
      mousePositionDec: 0,
      aladin: null,
      canvasWidthPx: 0,
      canvasHeightPx: 0,
      canvasWidthDeg: 0,
      canvasHeightDeg: 0,
      xFrames: 1,
      yFrames: 1,
      xOverlap: 10,
      yOverlap: 10,
      sensorBox: null,
      selectedCamera: "ZWO ASI2600MM-Pro",
      cameras: {
        "ZWO ASI2600MM-Pro": {
          name: "ZWO ASI2600MM-Pro",
          pixelWidth: 3.76,
          pixelHeight: 3.76,
          cameraWidthPixels: 6248,
          cameraHeightPixels: 4176,
        },
        "ZWO ASI1600MM-Pro": {
          name: "ZWO ASI1600MM-Pro",
          pixelWidth: 3.8,
          pixelHeight: 3.8,
          cameraWidthPixels: 4656,
          cameraHeightPixels: 3520,
        },
        "Atik 460EX": {
          name: "Atik 460EX",
          pixelWidth: 4.54,
          pixelHeight: 4.54,
          cameraWidthPixels: 2749,
          cameraHeightPixels: 2199,
        },
        "ZWO ASI183MC-Pro": {
          name: "ZWO ASI183MC-Pro",
          pixelWidth: 2.4,
          pixelHeight: 2.4,
          cameraWidthPixels: 5496,
          cameraHeightPixels: 3672,
        },
      },
      cameraNames: [],
    };
  },
  created: function () {
    this.cameraNames = ["Custom", ...Object.keys(this.cameras)];
  },
  computed: {
    canvasWidthPixelsPerDeg: function () {
      return this.canvasWidthPx / this.canvasWidthDeg;
    },
    canvasHeightPixelsPerDeg: function () {
      return this.canvasHeightPx / this.canvasHeightDeg;
    },
    canvasWidthArcSec: function () {
      return this.canvasWidthDeg * 60 * 60 * 60;
    },
    canvasHeightArcSec: function () {
      return this.canvasHeightDeg * 60 * 60 * 60;
    },
    canvasWidthArcSecPerPixel: function () {
      return this.canvasWidthArcSec / this.canvasWidthPx;
    },
    canvasHeightArcSecPerPixel: function () {
      return this.canvasHeightArcSec / this.canvasHeightPx;
    },
    raDecimalDegrees: function () {
      return this.ra;
    },
    raDecimalHours: function () {
      return (this.ra / 360) * 24;
    },
    raDecimalHoursMessage: function () {
      const hours = Math.floor(this.raDecimalHours);
      const minutes = Math.floor((this.raDecimalHours - hours) * 60);
      const seconds =
        Math.round(((this.raDecimalHours - hours) * 60 - minutes) * 60 * 100) /
        100;

      return `${this.raDecimalHours.toFixed(
        6
      )} hours; ${hours}h ${minutes}m ${seconds}s`;
    },
    decDecimalDegrees: function () {
      return this.dec;
    },
    decDecimalDegreesMessage: function () {
      const degrees = Math.floor(this.decDecimalDegrees);
      const minutes = Math.floor((this.decDecimalDegrees - degrees) * 60);
      const seconds =
        Math.round(
          ((this.decDecimalDegrees - degrees) * 60 - minutes) * 60 * 100
        ) / 100;

      return `${degrees}° ${minutes}m ${seconds}s`;
    },
    sensorWidthArcSecPerPixel: function () {
      return (
        (2 *
          Math.atan((this.pixelWidth * 1) / 1000 / (2 * this.focalLengthMM)) *
          180 *
          3600) /
        Math.PI
      );
    },
    sensorHeightArcSecPerPixel: function () {
      return (
        (2 *
          Math.atan((this.pixelHeight * 1) / 1000 / (2 * this.focalLengthMM)) *
          180 *
          3600) /
        Math.PI
      );
    },
    sensorFovWidthArcSec: function () {
      return this.sensorWidthArcSecPerPixel * this.cameraWidthPixels;
    },
    sensorFovHeightArcSec: function () {
      return this.sensorHeightArcSecPerPixel * this.cameraHeightPixels;
    },
    sensorFovWidthPixels: function () {
      return (
        (this.canvasWidthPixelsPerDeg * this.sensorFovWidthArcSec) / 60 / 60
      );
    },
    sensorFovHeightPixels: function () {
      return (
        (this.canvasHeightPixelsPerDeg * this.sensorFovHeightArcSec) / 60 / 60
      );
    },
    starfieldViews: function () {
      const frames = [];
      for (let i = 0; i < this.xFrames * this.yFrames; i++) {
        const raDec = this.sensorBox.getRaDec(i);

        const url = new URL(
          "https://starfield-api.darkdragonsastro.com/starfield/image"
        );
        url.searchParams.append("ra", raDec[0]);
        url.searchParams.append("dec", raDec[1]);
        url.searchParams.append("width", this.cameraWidthPixels);
        url.searchParams.append("height", this.cameraHeightPixels);
        url.searchParams.append("pixelSizeX", this.pixelWidth);
        url.searchParams.append("pixelSizeY", this.pixelHeight);
        url.searchParams.append("focalLength", this.focalLengthMM);
        url.searchParams.append("rotation", this.rotation);
        url.searchParams.append("download", "false");

        const frame = {
          view: url.href,
          download: null,
        };

        const fileName = `${this.search}_${zeroPad(
          i,
          (this.xFrames * this.yFrames).toString().length
        )}.fits`;

        url.searchParams.set("download", "true");
        url.searchParams.set("filename", fileName);

        frame.download = url.href;

        frames.push(frame);
      }

      return frames;
    },
  },
  watch: {
    selectedCamera: function () {
      if (this.selectedCamera == "Custom") return;
      this.cameraWidthPixels =
        this.cameras[this.selectedCamera].cameraWidthPixels;
      this.cameraHeightPixels =
        this.cameras[this.selectedCamera].cameraHeightPixels;
      this.pixelWidth = this.cameras[this.selectedCamera].pixelWidth;
      this.pixelHeight = this.cameras[this.selectedCamera].pixelHeight;

      window.localStorage.setItem("selectedCamera", this.selectedCamera);
      window.localStorage.setItem("cameraWidthPixels", this.cameraWidthPixels);
      window.localStorage.setItem(
        "cameraHeightPixels",
        this.cameraHeightPixels
      );
      window.localStorage.setItem("pixelWidth", this.pixelWidth);
      window.localStorage.setItem("pixelHeight", this.pixelHeight);
    },
    cameraWidthPixels: function () {
      if (
        this.selectedCamera != "Custom" &&
        this.cameras[this.selectedCamera].cameraWidthPixels !=
          this.cameraWidthPixels
      )
        this.selectedCamera = "Custom";
      this.drawSensorBox();
      window.localStorage.setItem("cameraWidthPixels", this.cameraWidthPixels);
    },
    cameraHeightPixels: function () {
      if (
        this.selectedCamera != "Custom" &&
        this.cameras[this.selectedCamera].cameraHeightPixels !=
          this.cameraHeightPixels
      )
        this.selectedCamera = "Custom";
      this.drawSensorBox();
      window.localStorage.setItem(
        "cameraHeightPixels",
        this.cameraHeightPixels
      );
    },
    pixelWidth: function () {
      if (
        this.selectedCamera != "Custom" &&
        this.cameras[this.selectedCamera].pixelWidth != this.pixelWidth
      )
        this.selectedCamera = "Custom";
      this.drawSensorBox();
      window.localStorage.setItem("pixelWidth", this.pixelWidth);
    },
    pixelHeight: function () {
      if (
        this.selectedCamera != "Custom" &&
        this.cameras[this.selectedCamera].pixelHeight != this.pixelHeight
      )
        this.selectedCamera = "Custom";
      this.drawSensorBox();
      window.localStorage.setItem("pixelHeight", this.pixelHeight);
    },
    xFrames: function () {
      this.drawSensorBox();
    },
    yFrames: function () {
      this.drawSensorBox();
    },
    xOverlap: function () {
      this.drawSensorBox();
    },
    yOverlap: function () {
      this.drawSensorBox();
    },
    focalLengthMM: function () {
      this.drawSensorBox();
      window.localStorage.setItem("focalLengthMM", this.focalLengthMM);
    },
    rotation: function () {
      this.drawSensorBox();
    },
    ra: function () {
      this.goto();
    },
    dec: function () {
      this.goto();
    },
  },
  beforeMount: function () {
    try {
      let selectedCamera = window.localStorage.getItem("selectedCamera");
      if (selectedCamera) this.selectedCamera = selectedCamera;

      let cameraWidthPixels = window.localStorage.getItem("cameraWidthPixels");
      if (cameraWidthPixels)
        this.cameraWidthPixels = parseInt(cameraWidthPixels);

      let cameraHeightPixels =
        window.localStorage.getItem("cameraHeightPixels");
      if (cameraHeightPixels)
        this.cameraHeightPixels = parseInt(cameraHeightPixels);

      let pixelWidth = window.localStorage.getItem("pixelWidth");
      if (pixelWidth) this.pixelWidth = parseFloat(pixelWidth);

      let pixelHeight = window.localStorage.getItem("pixelHeight");
      if (pixelHeight) this.pixelHeight = parseFloat(pixelHeight);

      let focalLengthMM = window.localStorage.getItem("focalLengthMM");
      if (focalLengthMM) this.focalLengthMM = parseInt(focalLengthMM);
    } catch (error) {
      console.error(error);
    }
  },
  mounted: function () {
    const self = this;

    this.aladin = A.aladin("#aladin-lite-div", {
      survey: "P/DSS2/color",
      fov: 2,
      target: "M31",
      showFullscreenControl: false,
    });

    this.aladin.setFoV((this.sensorFovWidthArcSec * 2) / 3600);

    //   AVAILABLE_CALLBACKS
    this.aladin.on("zoomChanged", function () {
      self.drawSensorBox();
    });
    this.aladin.on("positionChanged", function () {
      const raDec = self.aladin.getRaDec();

      self.ra = parseFloat(raDec[0].toFixed(6));
      self.dec = parseFloat(raDec[1].toFixed(6));
    });
    this.aladin.on("mouseMove", function (e) {
      self.mousePositionX = e.x;
      self.mousePositionY = e.y;
      self.mousePositionRA = e.ra;
      self.mousePositionDec = e.dec;
    });

    const resizeObserver = new ResizeObserver(function () {
      setTimeout(function () {
        self.drawSensorBox();
      }, 500);
    });
    resizeObserver.observe(this.aladin.aladinDiv);
  },
  methods: {
    view: function (i) {
      const raDec = this.sensorBox.getRaDec(i);

      const url = new URL(
        "https://starfield-api.darkdragonsastro.com/starfield/image"
      );
      url.searchParams.append("ra", raDec[0]);
      url.searchParams.append("dec", raDec[1]);
      url.searchParams.append("width", this.cameraWidthPixels);
      url.searchParams.append("height", this.cameraHeightPixels);
      url.searchParams.append("pixelSizeX", this.pixelWidth);
      url.searchParams.append("pixelSizeY", this.pixelHeight);
      url.searchParams.append("focalLength", this.focalLengthMM);
      url.searchParams.append("rotation", this.rotation);
      url.searchParams.append("download", "false");

      let link = document.createElement("a");
      link.setAttribute("href", url.href);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    download: function (i) {
      const raDec = this.sensorBox.getRaDec(i);

      const fileName = `${this.search}_${zeroPad(
        i,
        (this.xFrames * this.yFrames).toString().length
      )}.fits`;

      const url = new URL(
        "https://starfield-api.darkdragonsastro.com/starfield/image"
      );
      url.searchParams.append("ra", raDec[0]);
      url.searchParams.append("dec", raDec[1]);
      url.searchParams.append("width", this.cameraWidthPixels);
      url.searchParams.append("height", this.cameraHeightPixels);
      url.searchParams.append("pixelSizeX", this.pixelWidth);
      url.searchParams.append("pixelSizeY", this.pixelHeight);
      url.searchParams.append("focalLength", this.focalLengthMM);
      url.searchParams.append("rotation", this.rotation);
      url.searchParams.append("download", "true");
      url.searchParams.append("filename", fileName);

      let link = document.createElement("a");
      link.setAttribute("href", url.href);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    searchObject: function () {
      const self = this;

      this.searching = true;

      Sesame.getTargetRADec(
        this.search,
        function (val) {
          self.ra = val.ra;
          self.dec = val.dec;

          self.searching = false;
          self.searchError = "";
        },
        function (err) {
          self.searchError =
            "Could not find object. Please try a different search.";
          console.log(err);
          self.searching = false;
        }
      );
    },
    goto: debounce(function () {
      this.aladin.gotoRaDec(this.ra, this.dec);
    }, 500),
    drawSensorBox: debounce(function () {
      const fov = this.aladin.getFov();

      this.canvasWidthDeg = fov[0];
      this.canvasHeightDeg = fov[1];

      this.canvasWidthPx = this.aladin.view.width;
      this.canvasHeightPx = this.aladin.view.height;

      this.aladin.removeLayers();
      var overlay = A.graphicOverlay({ color: "#f44336", lineWidth: 1 });
      this.aladin.addOverlay(overlay);

      this.sensorBox = new SensorBox(
        this.sensorFovWidthPixels,
        this.sensorFovHeightPixels,
        this.rotation,
        this.xFrames,
        this.yFrames,
        this.xOverlap,
        this.yOverlap,
        this.focalLengthMM,
        this.pixelWidth,
        this.pixelHeight,
        this.cameraWidthPixels,
        this.cameraHeightPixels
      );

      overlay.add(this.sensorBox);
    }, 10),
  },
  props: {
    msg: String,
  },
};
</script>

<style scoped>
</style>
